import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({
  selector: 'app-dashboard-to-do',
  templateUrl: './dashboard-to-do.component.html',
  styleUrls: ['./dashboard-to-do.component.css']
})
export class DashboardToDoComponent implements OnInit {
  @Input() employeeId: string;
  supportReqList: Array<SupportRequestDataModel>;
  barData: any;
  doughnutData: any;
  barOptions: any;
  expectedDate: Array<any> = [];
  tSHours: Array<any> = [];
  approvedTSHours: Array<any> = [];
  selectedBarIndex: any;
  supportReqObj: SupportRequestDataModel;
  supportReqObj2: SupportRequestDataModel;
  doughnutFlag: boolean = false;
  workLogList: Array<any>;
  approveWorklog: any;
  elementIndex: any;
  updateWorklogRecord: any;

  constructor(
    public dashboardService: DashboardService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.supportReqObj = new SupportRequestDataModel();
    this.supportReqObj2 = new SupportRequestDataModel();
    // this.getLastWeekWorkLog();
    this.onTabChange('thismonth');
  }

  async onTabChange(event) {
    this.spinner.show();
    this.clearChartData();

    await this.dashboardService.getWorkLogLast30Days(event).subscribe(
      (resWorkLog: any) => {
        if (resWorkLog.isSuccessful) {
          this.supportReqList = resWorkLog.data;
          this.supportReqList?.sort((a, b) => {
            return <any>new Date(a.tSdate) - <any>new Date(b.tSdate);
          });
          this.getApprovedWorkLogLast30Days(event); // Fetch the approved work log data after getting the work log data
        } else {
          this.spinner.hide();
         
        }
      },
      (error) => {
        this.spinner.hide();
        // this.toastr.error(error.message);
      }
    );
  }
   clearChartData() {
    this.expectedDate = [];
    this.tSHours = [];
    this.approvedTSHours = [];
    this.barData = {};
  }

  getApprovedWorkLogLast30Days(event) {
    this.dashboardService.getApprovedWorkLogLast30Days(event).subscribe(
      (resp: any) => {
        if (resp.isSuccessful && resp.data) {
          this.approveWorklog = resp.data;
          this.approveWorklog?.sort((a, b) => {
            return <any>new Date(a.tSdate) - <any>new Date(b.tSdate);
          });
        } else {
          this.spinner.hide();
          // If the approved work log data is null, proceed without it
          this.approveWorklog = [];
          // this.toastr.info('No approved work logs found. Proceeding with the available data.');
        }
        this.getInfoForBarChart(); // Proceed with generating the chart regardless of whether the approved work logs exist
      },
      (error) => {
        this.spinner.hide();
        // this.toastr.error(error.message);
      }
    );
  }
  

  async getInfoForBarChart() {
    this.getExpectedResDateList();
    this.getTSHours();
    this.getApprovedTSHours(); // Collect approved hours
    this.getBarChart(); // Now generate the combined chart
  }

  async getExpectedResDateList() {
    this.supportReqList?.forEach((i) => {
      this.expectedDate.push(i.expectedResDate);
    });
  }

  async getTSHours() {
    this.supportReqList?.forEach((j) => {
      this.tSHours.push(j.tShours);
    });
  }

  async getApprovedTSHours() {
    const dateToApprovedHoursMap: any = {};

    // Create a mapping from date to approved hours
    this.approveWorklog?.forEach((log) => {
      dateToApprovedHoursMap[log.expectedResDate] = log.tShours; // Assuming `expectedResDate` and `approvedTSHours`
    });

    // Now map approved hours to match the same dates in `expectedDate`
    this.expectedDate.forEach((date) => {
      this.approvedTSHours.push(dateToApprovedHoursMap[date] || 0); // Fill with 0 if no approved hours for that date
    });
  }

  async getBarChart() {
    
    this.barData = {
      labels: this.expectedDate, // Dates will be shown on x-axis
      datasets: [
        {
          label: 'Work Log Hours',
          data: this.tSHours,
          backgroundColor: '#39C0ED',
        },
        {
          label: 'Approved Work Log Hours',
          data: this.approvedTSHours, // Aligned approved hours
          backgroundColor: '#00b74a',
        }
      ]
    };

    this.barOptions = {
      legend: {
        display: true, // Show the legend to differentiate between the datasets
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 2,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    this.spinner.hide();
  }

  async onClickBar(e: any) {
    console.log('e', e)
    this.elementIndex = e?.element?._datasetIndex
    if(e.element._datasetIndex == 0){

        this.spinner.show();
        this.selectedBarIndex = e.element._index;
        await this.getIndividualBarData();
    }
  }

  async getIndividualBarData() {
    this.supportReqList?.filter((i, index) => {
      if (index == this.selectedBarIndex) {
        this.supportReqObj = i;
      }
    });

    this.supportReqObj.ID = this.employeeId;
    await this.getClickedDayLog();
  }

  async getClickedDayLog() {
    await this.dashboardService
      .getParticularDayWorkLog(this.supportReqObj.tSdate)
      .subscribe(
        (resParticularDay: any) => {
          if (resParticularDay.isSuccessful) {
            this.supportReqObj2 = resParticularDay.data;

            //used for doughnut chart data
            this.workLogList = this.supportReqObj2.toDoList;
            this.getDoughnutChart();
          } else {
            this.spinner.hide();
            this.toastr.error(resParticularDay.messageDetail.message, 'Error');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.messageDetail.message, 'Error');
        }
      );
  }

  async getDoughnutChart() {
    this.doughnutData = {
      labels: this.supportReqObj2?.label,
      datasets: [
        {
          data: this.supportReqObj2.value,
          backgroundColor: ['#6edbff', '#4380e6', '#39C0ED', '#7fffff', '#9380e6', '#10f9ff'],
        },
      ],
    };
    this.spinner.hide();
  }

  onClickDoughnut() {
    this.doughnutFlag = !this.doughnutFlag;
  }

  // navigating to the update to-do page with to-do id.
  toDoDetails(workLog: any) {
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + workLog.itemId]);
  }

  // navigating to the my-worklog page with worklog id.
  updateWorklog(workLog: any) {
    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + workLog.id]);
  }

  close(event:any){
console.log('event',event)
this.elementIndex = 1
  }

  updateWorklogdata(record:any){
    this.updateWorklogRecord = record
  }
  updateWorklogrecord(){
    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + this.updateWorklogRecord.id]);
  }
}
