import { Directive, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAutoResizeField]'
})
export class AutoResizeFieldDirective implements AfterViewInit, OnChanges {
  // Input to observe dynamic changes like `formControl` values or bindings
  @Input() value: string | undefined;

  constructor(private el: ElementRef<HTMLTextAreaElement>) {}

  ngAfterViewInit(): void {
    // Resize the textarea when the component/view is initialized
    this.resize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Watch for changes to the `value` property and resize dynamically
    if (changes['value'] && changes['value'].currentValue !== changes['value'].previousValue) {
      this.resize();
    }
  }

  private resize(): void {
    const textarea = this.el.nativeElement;

    // Forcefully resize disabled or readonly textareas
    textarea.style.overflow = 'hidden'; // Prevent scrollbars
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height to content
  }
}
