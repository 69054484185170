import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from '../../../_services/feedback.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';
import { TableModule } from 'primeng/table';
@Component({
  selector: 'app-update-feed-back',
  templateUrl: './update-feed-back.component.html',
  styleUrls: ['./update-feed-back.component.scss']
})
export class UpdateFeedBackComponent implements OnInit {
  feedbackId: string;
  feedbackForm: FormGroup;
  Updatefeedback: any;
  upLoadFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  datasplit: any;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sharepointServices: SharepointServiceService
  ) {
    if (this.route.snapshot.paramMap.get('id')) {
      this.feedbackId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.feedbackForm = this.fb.group({
      regardingTo: [''],
      feedBackTitle: [''],
      feedBackDescription: [''],
      reportedBy: [''],
      reportedOn: [''],
      ResolvedBy: [''],
      resolution: [''],
      owner: [''],
      status: [''],
      actionTakenOn: ['']
      // other: ['']
    });

    this.GetMyFeedbacksByFeedBackId();
  }

  GetMyFeedbacksByFeedBackId() {

    this.spinner.show();
    this.feedbackService.GetMyFeedbacksByFeedBackId(this.feedbackId).subscribe((feedbckResp: any) => {
      if (feedbckResp.isSuccessful == true) {
        this.Updatefeedback = feedbckResp.data[0]
        console.log('responce', this.Updatefeedback[0])
        this.feedbackForm.patchValue({
          regardingTo: this.Updatefeedback.regardingTo.label,
          feedBackTitle: this.Updatefeedback.feedBackTitle,
          feedBackDescription: this.Updatefeedback.feedBackDescription,
          reportedBy: this.Updatefeedback.reportedBy,
          reportedOn: this.Updatefeedback.reportedOn,
          ResolvedBy: this.Updatefeedback.actionTakenBy,
          resolution: this.Updatefeedback.resolution,
          owner: this.Updatefeedback.actionTakenBy,
          status: this.Updatefeedback?.status?.label,
          actionTakenOn: this.Updatefeedback?.actionTakenOn
        })
        this.spinner.hide();
      }
      else if (feedbckResp.isSuccessful == false) {
        this.toastr.error(feedbckResp?.messageDetail?.message, 'Error')
        this.spinner.hide();
      }

      this.getToDsAttachments();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  async uploadFiles(attachFiles: any) {
    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        this.spinner.hide();
        // this.getData();

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

    //CRM SharePoint API
    async getToDsAttachments() {
      this.downloadFileList = [] as Array<UploadFileDataModel>;
      await this.sharepointServices.GetAttachmentFromSharePointfeedback(this.feedbackId).subscribe((fileRes: any) => {
        if (fileRes.isSuccessful == true) {// success
          this.downloadFileList = fileRes.data;
        }
  
        else if (fileRes.isSuccessful == false) {
          // if(fileRes.Data != null){q
          //   this.toastr.error(fileRes.Message, 'Error');
          // }        
          this.spinner.hide();
  
        }
        // this.getWorkLogList();
  
      }, error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message);
      });
    }
    download(file: any) {
      debugger
  
      import("file-saver").then(FileSaver => {
        var contentbase64 = file.bytes;
        var contenttype = file.fileType;
        var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
        //download file
        FileSaver.saveAs(fileFormat, file.fileName);
      });
    }

    selectAttachments(event) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          if (file.size > 5242880) { // 5 MB in bytes
            this.toastr.error("File size greater 5mb is not acceptable");
            continue; // Skip this file and move on to the next one
          }
          this.returnFileSizeAccount(file.size);
          const reader = new FileReader();
          reader.onload = (res: any) => {
            const uploadFile = {
              filename: "",
              filetype: "",
              bytes: "",
              ID: "",
              Name: "",
            };
            this.datasplit = res.target.result;
            const base64 = this.datasplit.split(",");
            uploadFile["ID"] = "";
            uploadFile["Name"] = "solz_supportrequest";
            uploadFile["bytes"] = base64[1];
            uploadFile["filename"] = file.name;
            uploadFile["filetype"] = file.type;
            this.upLoadFileList.push(uploadFile);
          };
          reader.readAsDataURL(file);
        }
      }
    }
  
  
  
    returnFileSizeAccount(number) {
      var num = (number / 1048576);//mb
      if (num > 5) {
        this.toastr.error("File size greater 5mb is not acceptable");
      }
    }
  
    deleteFile(index: number) {
      this.upLoadFileList.splice(index, 1);
  
      // Clear the file input element
      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      fileInput.value = '';
    }

  onSubmit() {

  }



  //Support Request Form Control
  get srf() {
    return this.feedbackForm.controls;
  }



}
