import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
//import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
//import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './New Portal UI/authorization/login/login.component';
import { ForgotPasswordComponent } from './New Portal UI/authorization/forgot-password/forgot-password.component';
import { PortalRoutingModule } from './New Portal UI/portal-routing.module';
import { AlertModule } from 'ngx-bootstrap/alert';
//import { AuthService } from './New Portal UI/authorization/auth.service';
import { AuthService } from './_services/auth.service';
//import { LeaveService } from './New Portal UI/leave-requests/leave.service';
import { LeaveService } from './_services/leave.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2CompleterModule } from 'ng2-completer';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';import { AuthguardService } from './_services/authguard.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { ChangePasswordComponent } from './New Portal UI/authorization/change-password/change-password.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import {PaginatorModule} from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import {AutoCompleteModule } from 'primeng/autocomplete';
import { SalarySlipComponent } from './New Portal UI/salary-slip/salary-slip.component';
import { HandlePasteDirective } from './_directives/handle-paste.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './_services/auth.interceptor';
import { GeneratePasswordComponent } from './New Portal UI/authorization/generate-password/generate-password.component';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PortalRoutingModule,
    AlertModule,
    NgbModule,
    Ng2CompleterModule,
    FullCalendarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgDynamicBreadcrumbModule,
    ModalModule.forRoot(),
    ButtonModule,
    DialogModule,
    PaginatorModule,
    CheckboxModule,
    AutoCompleteModule
  ],

  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SalarySlipComponent,
    HandlePasteDirective,
    GeneratePasswordComponent,
   
  
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthService,
    LeaveService,
    AuthguardService
  ],  

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
