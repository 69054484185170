import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiURL: string = "";
  authapiURL: string = "";

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL;
  }

  ngOnInit() {

  }

  // used for dashboard pie chart
  getTimeLoggedThisWeek(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetTimeLoggedThisWeek');
  }

  // used for dashboard pie chart
  getMinTimeLoggedThisWeek(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'EmployeeAttendance/GetEmployeeCurrentWeekMinTimeLog');
  }

  // used for dashboard pie chart
  getTimeLoggedLastWeek(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetTimeLoggedLastWeek/' ); 
  }

  // used for dashboard pie chart
  getMinTimeLoggedLastWeek(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'EmployeeAttendance/GetEmployeeLastWeekMinTimeLog' );
  }

  // used for dashboard pie chart
  getBalanceLeaveDashboard(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetBalanceLeaveDashboard/' );
  }

  getLeavesGraphData(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetLeavesCountForDashboardGraph');
  }

  getSoluzioneHolidays(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetSoluzioneHolidaysDashboard/');
  }

  getUpcomingEmployeeBirthdays(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetSoluzioneUpcomingBirthdays/');
  }

  getWorkLogLast30Days(selectedMonth:any): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetWorkLogThisMonthAndLastMonth?Timeframe=' + selectedMonth );
  }

  getApprovedWorkLogLast30Days(selectMonth : any): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/ApprovedWorkLogLast30Days?Timeframe=' + selectMonth );
  }

  // getting that particular day log by posting that day's data.
  getParticularDayWorkLog(supportReqModel: any) {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/ToDosOnWorkLogDate?workLogDate='+ supportReqModel);
  }

  // used for getting to do calendar data.
  getToDoCalendar(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/ToDoCallendar');
  }

  //Get Soluzione Upcoming Event dashboard activity days 
  getActivityDays(): Observable<any> {
    return this.HttpClient.get(this.authapiURL +'Dashboard/GetSoluzioneUpcomingEvent');

  }

  getEmployeeOfTheMonth(): Observable<any> {
    return this.HttpClient.get(this.authapiURL +'Dashboard/GetSoluzioneAwards');

  }

  //get employee technical skills 
  getEmployeeTechSkills(): Observable<any> {
    return this.HttpClient.get(this.authapiURL +'Dashboard/GetEmployeeSkills');

  }

  getAwardsImages(id:any): Observable<any>{
    return this.HttpClient.get(this.authapiURL+'Dashboard/GetSoluzioneAwardsImageByAwardsId/?awardId='+id)
  }

  getTop15TimeSheetRecords() {
    return this.HttpClient.get(this.authapiURL + 'Dashboard/GetTopFifteenRecordsOfTimeSheet' );
  }


}
