import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { Table } from 'primeng/table';
// import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-my-to-dos',
  templateUrl: './my-to-dos.component.html',
  styleUrls: ['./my-to-dos.component.css']
})
export class MyToDosComponent implements OnInit {
  @ViewChild('parentTable') parentTable: Table;
  // @ViewChild('config') columnFilterConfig !: ElementRef; 
  userData: EmployeeDataModel;
  contactId: any;
  empId: any;
  custTableFlag: number = 0;
  empTableFlag: number = 0;
  allProjectList: Array<ProjectDataModel> = [];
  toDoList: Array<ToDosDataModal> = [];
  toDosObj: ToDosDataModal;
  ContactLoggedIn: boolean;
  isManager: any;
  heading: string;
  parameter: string;
  fieldsToExport: Array<any> = [];
  isBugList: any;
  ToggleFlag: any;
  checked: boolean = false;
  filterValue: string = '';
  searchFilterVal: any;
  expandedRow: any = null;
   toDoLists = [
    {
      itemNumber: 1,
      project: { name: "Project A" },
      title: "Main Task 1",
      userPriority: { label: "High" },
      plannedStartDate: new Date(),
      plannedEndDate: new Date(),
      workStatus: { label: "In Progress" },
      nestedData: [
        {
          itemType: "Type A",
          itemNumber: "12345",
          project: "Project Alpha",
          priority: "High",
          title: "Child Task 1",
          workStatus: "In Progress",
          plannedStartDate: new Date(),
          plannedEndDate: new Date(),
          effortSpent: "5 hours",
        },
        {
          itemType: "Type B",
          itemNumber: "67890",
          project: "Project Beta",
          priority: "Medium",
          title: "Child Task 2",
          workStatus: "Completed",
          plannedStartDate: new Date(),
          plannedEndDate: new Date(),
          effortSpent: "3 hours",
        },
      ],
    },
    {
      itemNumber: 2,
      project: { name: "Project B" },
      title: "Main Task 2",
      userPriority: { label: "Medium" },
      plannedStartDate: new Date(),
      plannedEndDate: new Date(),
      workStatus: { label: "Not Started" },
      nestedData: [
        {
          itemType: "Type C",
          itemNumber: "13579",
          project: "Project Gamma",
          priority: "Low",
          title: "Child Task 3",
          workStatus: "Pending",
          plannedStartDate: new Date(),
          plannedEndDate: new Date(),
          effortSpent: "2 hours",
        },
      ],
    },
   ];
  

  constructor(public toDosService: ToDosService,
    // private primeNGConfig: PrimeNGConfig,
    private storage: StorageMap,
    public supportReqService: SupportRequestService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) { }


  ngOnInit(): void {
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.parameter = "";
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  // toggleRow(row: any): void {
  //   debugger
  //   // If the clicked row is already expanded, collapse it
  //   if (this.expandedRow === row) {
  //     this.expandedRow = null;
  //   } else {
  //     // Otherwise, expand the clicked row and collapse any other
  //     this.expandedRow = row;
  //   }
  // }

  // isRowExpanded(row: any): boolean {
  //   debugger
  //   return this.expandedRow === row;
  // }

  //   onRowExpand(event: TableRowExpandEvent) {
  //     this.messageService.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
  // }

  // onRowCollapse(event: TableRowCollapseEvent) {
  //     this.messageService.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  // }
  // ngAfterViewInit() {
  //   this.toDosService.latestStateInspection.subscribe(resp => {
  //     if (resp) {
  //       this.searchFilterVal = resp;
  //       this.setFilterValue(resp);
  //     }
  //   });
  // }
  //   applyFilter(event: any) {
  //     console.log("event",event)
  //     const filterValue = event.target.value;
  //     // Apply filter logic here using filterValue
  //     // For example, you can call a function to filter your data
  //     // this.filterData(filterValue);
  //     this.toDosService.updateStateInspection(filterValue);
  //   }

  //   filterData(filterValue: string) {
  //     // Implement your filter logic here
  //     // For example, filter your data using filterValue
  //   }

  //   setFilterValue(value: string) {
  //     
  //     const columnFilterInput = this.columnFilterConfig.nativeElement.querySelector('input'); // Assuming the input element is used for the filter
  //     if (columnFilterInput) {
  //       columnFilterInput.value = value;
  //       columnFilterInput.dispatchEvent(new Event('input')); 
  //   }
  //   }
  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myToDos: EmployeeDataModel) => {
      this.userData = myToDos.userProfile;
      // If Contact User is logged in
      if (this.userData.contact) {
        this.ContactLoggedIn = true;
        this.contactId = this.userData.contact?.ID;
        this.getCustomerProjects();
        // this.GetToDoListBasedOnFilter();
      }

      // If Soluzione Emp/Manager logged in
      else if (this.userData.userId) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.userId
        this.getEmployeeProjects(this.userData.userId);
        // this.GetToDoListBasedOnFilter();
        this.GetToDoListBasedOnFilter(3, 0)
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }



  //for customers.
  async getCustomerProjects() {

    await this.toDosService.getProjectsByCustomerId(this.contactId).subscribe((custProjects: any) => {

      if (custProjects.ResponseCode == 100) { // success

        this.allProjectList = custProjects.Data;
        this.toDosObj.customerId = this.contactId;
        // this.getCustOpenToDos(this.parameter);
      }


    },
      //  error => {
      //   this.spinner.hide();
      //   this.toastr.error(error.message);
      // }
    );
  }

  async getEmployeeProjects(contactId) {

    await this.toDosService.getProjectsByCustomerId(contactId).subscribe((empProjects: any) => {
      this.spinner.hide();
      if (empProjects.ResponseCode == 100) { // success

        this.allProjectList = empProjects.Data;
        this.toDosObj.ID = this.empId;
        // this.getToDosBasedUponParameter(this.parameter); // while loading initially, the parameter is initialized as an empty string and passed here.
        // this.GetToDoListBasedOnFilter();
      }
      else if (empProjects.ResponseCode == 999) {
        this.toastr.error(empProjects.Message, 'Error');
      }
    },
      error => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
    );
  }

  async getToDosBasedUponParameter(val: string) {

    this.spinner.show();
    this.parameter = val;
    this.toDosObj.pageNumber = 1;
    if (this.parameter == "") {

      this.empTableFlag = 1;
      await this.toDosService.getOpenTodosOfEmployeeById(this.toDosObj).subscribe((res1: any) => {
        if (res1.ResponseCode == 100) { //success
          this.heading = "ITEMS I'M WORKING ON";
          this.toDoList = res1.Data;
          res1.Data.forEach(element => {
            if (element.isBug === true) {
              element.isBugyes = 'Bug'
            }
            else {
              element.isBugyes = 'Task'
            }

          });

          //  this.isBugList = res1.Data.filter(f => f.isBug===true.includes(cvf));
          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res1.ResponseCode == 999) {
          this.toastr.error(res1.Message, 'Error');
        }

        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          this.toastr.error(error.message);
        }
      );
    }

    else if (this.parameter == "All Items of My Projects") {
      this.empTableFlag = 2;
      this.toDosObj.filterOpenItemByProjectStatus = false;
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res2: any) => {
        if (res2.ResponseCode == 100) { // success
          this.heading = "ALL ITEMS OF MY PROJECTS";
          this.toDoList = res2.Data;
          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res2.ResponseCode == 999) {
          this.toastr.error(res2.Message, 'Error');
        }

        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          this.toastr.error(error.message);
        }
      );
    }

    else if (this.parameter.search("Open Items by Project") == 0) {
      this.empTableFlag = 3;
      var splitId = this.parameter.split("|");
      this.toDosObj.projectId = splitId[1];
      this.toDosObj.filterOpenItemByProjectStatus = true;
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res3: any) => {
        if (res3.ResponseCode == 100) { // success
          this.heading = splitId[0].toUpperCase();
          this.toDoList = res3.Data;
          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res3.ResponseCode == 999) {
          this.toastr.error(res3.Message, 'Error');
        }

        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          this.toastr.error(error.message);
        }
      );
    }

    else if (this.parameter.search("All Items by Project") == 0) {
      this.empTableFlag = 4;
      var splitId = this.parameter.split("|");
      this.toDosObj.projectId = splitId[1];
      this.toDosObj.filterOpenItemByProjectStatus = false;
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res4: any) => {
        if (res4.ResponseCode == 100) { // success
          this.heading = splitId[0].toUpperCase();
          this.toDoList = res4.Data;

          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res4.ResponseCode == 999) {
          this.toastr.error(res4.Message, 'Error');
        }

        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          this.toastr.error(error.message);
        }
      );
    }
  }

  updateToDo(record: { id: string, itemType: any }) {
    if(record?.itemType?.value ===674180001){
      const url = this.router.serializeUrl(
        
        this.router.createUrlTree([`/ESSPortal/to_dos/update_UserStory/${record.id}`])
      );
      window.open(`${window.location.origin}/#${url}`, '_blank');
    }

    else{

      const url = this.router.serializeUrl(
        
        this.router.createUrlTree([`/ESSPortal/to_dos/update_to_do/${record.id}`])
      );
      window.open(`${window.location.origin}/#${url}`, '_blank');
    }
  }


  // sending to-do id to my worklog page.
  createWorkLog(id: string) {
    // this.router.navigate(['/#/ESSPortal/to_dos/my_worklog/AddWork/' + id]);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/ESSPortal/to_dos/my_worklog/AddWork/${id}`])

    );
    window.open(`${window.location.origin}/#${url}`)
  }

  // creating user readable list.
  createExportList() {debugger


    for (let i = 0; i < this.toDoList.length; i++) {
      var ex = {
        Item_No: "",
        // Created_On: null,
        Title: "",
        ItemsType: "",
        // WorkType: "",
        Project_Name: "",
        // Item_Description: "",
        // Repro_Steps: "",
        Parent_Title: "",
        User_Priority: null,
        // Type_Of_Item: null,
        // Date_Moved: null,
        // Testing_Environment: null,
        // Expected_Res_Date: null,
        // Comment: "",
        // Description_Of_Resolution: "",
        Work_Status: null,
        // Is_Client_Reported: "",
        // Assignee_Name: "",
        // Assigned_Reviewer_Name: "",
        // Implementation_Effort: 0,
        // Start_Date: null,
        // Review_Effort: 0,
        // isBug: ""
      }

      ex.Item_No = this.toDoList[i].itemNumber;
      // ex.Created_On = this.toDoList[i].createdon;
      ex.Title = this.toDoList[i].title;
      ex.ItemsType = this.toDoList[i].itemType?.label;
      // ex.WorkType = this.toDoList[i].typeOfItem?.Label;
      ex.Project_Name = this.toDoList[i].project.name;
      // ex.Item_Description = this.toDoList[i].itemDescription;
      // ex.Repro_Steps = this.toDoList[i].reproSteps;
      ex.Parent_Title = this.toDoList[i].userStoryTitle;
      // ex.User_Priority = this.toDoList[i].userPriority?.Label;
      // ex.Type_Of_Item = this.toDoList[i].typeOfItem?.Label;
      // ex.Date_Moved = this.toDoList[i].dateMoved;
      // ex.Testing_Environment = this.toDoList[i].testingEnvironment?.Label;
      // ex.Expected_Res_Date = this.toDoList[i].expectedResDate;
      // ex.Comment = this.toDoList[i].comment;
      // ex.Description_Of_Resolution = this.toDoList[i].descriptionOfResolution;
      ex.Work_Status = this.toDoList[i].workStatus?.label;


      // if (this.toDoList[i].isClientReported == false || this.toDoList[i].isClientReported == null) {
      //   ex.Is_Client_Reported = "No";
      // }

      // else {
      //   ex.Is_Client_Reported = "Yes";
      // }
      // ex.Assignee_Name = this.toDoList[i].assigneeName;
      // ex.Assigned_Reviewer_Name = this.toDoList[i].assignedReviewerName;
      // ex.Implementation_Effort = this.toDoList[i].implementationeffort;
      // ex.Start_Date = this.toDoList[i].startDate;
      // ex.Review_Effort = this.toDoList[i].revieweffort;
      // // ex.isBug = this.toDoList[i].isBug
      // if (this.toDoList[i].isBug == false || this.toDoList[i].isBug == null) {
      //   ex.isBug = "No";
      // }
      // else {
      //   ex.isBug = "Yes";
      // }
      this.fieldsToExport[i] = ex;
    }
  }


  exportToExcel() {debugger
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.fieldsToExport);
      const workbook = { Sheets: { 'Exporting_Tasks': worksheet }, SheetNames: ['Exporting_Tasks'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Exporting_Tasks");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }
  //Toggle for Expand And collapsed Table Row Data
  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;

  }

  isRowExpanded(record: any): boolean {
    return this.expandedRow === record;
  }

  toggleRowExpansion(table: any, record: any): void {
    if (this.isRowExpanded(record)) {
      this.expandedRow = null; // Collapse the row
      table.toggleRow(record); // PrimeNG-specific collapse logic
    } else {
      if (this.expandedRow) {
        table.toggleRow(this.expandedRow); // Collapse the previously expanded row
      }
      this.expandedRow = record; // Set the new row as expanded
      table.toggleRow(record); // Expand the new row
    }
  }
  GetToDoListBasedOnFilter(filterId, itemType) {
    this.spinner.show();
    debugger
    if (filterId == 1 && itemType == 0) {
      this.heading = " My Active Items";
    }
    else if (filterId == 2 && itemType == 0) {
      this.heading = "Not Started Items";
    }
    else if (filterId == 3 && itemType == 0) {
      this.heading = "Items I'm Working On";
    }
    else if (filterId == 4 && itemType == 0) {
      this.heading = "Pending Items";
    }
    else if (filterId == 3 && itemType == 674180003) {
      this.heading = "Bugs I'm Working On";
    }
    else if (filterId == 3 && itemType == 674180001) {
      this.heading = "User Stories I'm Working On";
    }
    else if (filterId == 5 && itemType == 0) {
      this.heading = "Completed Items";
    }
    this.toDosService.GetToDoListBasedOnFilter(filterId, itemType).subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = [] 
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }

  GetActiveItemsInMyProject(){
    this.spinner.show();
    this.heading = " Active Items in My Projects";
    this.toDosService.GetActiveItemsInMyProject().subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = [] 
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }


  GetGeneralTaskListInMyProject(){
    this.spinner.show();
    this.heading = " General Tasks";
    this.toDosService.GetGeneralTaskListInMyProject().subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = [] 
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }

}
