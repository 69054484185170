import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import * as moment from 'moment';
import { stringify } from 'querystring';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';

@Component({
  selector: 'app-update-performance-review-form',
  templateUrl: './update-performance-review-form.component.html',
  styleUrls: ['./update-performance-review-form.component.scss']
})
export class UpdatePerformanceReviewFormComponent implements OnInit {
  allQuestionData: any
  goalsResponce: any
  fullName: string;
  subjectiveQue: any[] = [];
  objectiveQue: any[] = [];
  feedbackQue: any[] = [];

  empGoalsForm: FormGroup;
  PerformanceReviewForm: FormGroup

  RatingList: any[]
  workFormSubmitted: boolean;
  userData: EmployeeDataModel;
  // updateQue: any[] = [];
  isFormDisabled: boolean = false; // Variable to track form disable state
  responseId: any;
  PerformanceRevName: any;
  todayDate = new Date();
  minDate: string;
  maxDate: string
  secondGoalMinDate: string
  secondGoalmaxDate: string
  thirdGoalMinDate: string
  thirdGoalMaxDate: string
  description: any;
  sequenceNumber: any;
  Saveresponse: any;
  PerformanceRevId: any;
  ReviewDataLst: { PerFormanceRevStatus: any; }[];
  reviewRespId: any;
  yearlyReviewStatus: any;


  // questionType: any;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private PerformanceReviewService: PerformanceReviewService,
    private MasterDropdownServiceService: MasterDropdownServiceService,
    private storage: StorageMap,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute

  ) {

    this.responseId = this.route.snapshot.paramMap.get('id');
    this.PerformanceReviewForm = this.fb.group({
      // Define your form controls here
      Managers: ['', Validators.required],
      Projects: ['', Validators.required],
      rating: ['', Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      goal1: ['', Validators.required],
      goal2: ['', Validators.required],
      goal3: ['', Validators.required],
      expectedOutcomes1: ['', Validators.required],
      expectedOutcomes2: ['', Validators.required],
      expectedOutcomes3: ['', Validators.required],
      achievedDate1: ['', Validators.required],
      achievedDate2: ['', Validators.required],
      achievedDate3: ['', Validators.required],
      // appQues: this.fb.array([]),
      // Add other form controls as needed


    });
    // Calculate minDate and maxDate for according to Goals of the current year
    const currentYear = this.todayDate.getFullYear();
    this.minDate = moment(`${currentYear}-04-01`).format('YYYY-MM-DD');
    this.maxDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DD');
    this.secondGoalMinDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DD');
    this.secondGoalmaxDate = moment(`${currentYear}-09-30`).format('YYYY-MM-DD');
    this.thirdGoalMinDate = moment(`${currentYear}-10-01`).format('YYYY-MM-DD');
    const nextYear = currentYear + 1;
    // Append the range from January to March of the next year
    this.thirdGoalMaxDate = moment(`${nextYear}-03-31`).format('YYYY-MM-DD');

  }
  ngOnInit(): void {

    this.spinner.show();

    this.responseId = this.route.snapshot.paramMap.get('id');
    // this.GetPerfReviewQuetions();
    this.GetPerfReviewQuetions()
    this.getDropDownRatingReviewStatus();
    this.getData();
    // this.GetPerfReviewQuetions();

  }
  patchDynamicControlValue(controlName: string, value: any) {
    if (this.PerformanceReviewForm.contains(controlName)) {
      if (value !== "null") {
        this.PerformanceReviewForm.get(controlName)?.patchValue(value);
      }
    }


  }

  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((empDetailsetails: EmployeeDataModel) => {

      this.userData = empDetailsetails?.userProfile;
      // If Contact User is logged in
      this.userData.ID = this.userData?.userId;
      this.userData.fullName = this.userData?.fullName;
      this.userData.designation = this.userData?.designation
      this.userData.reportingManager = this.userData?.reportingManager
      if (this.userData?.reportingManager?.id) {

        this.userData.reportingManagerID = this.userData?.reportingManager?.id
      }

      this.PerformanceReviewForm.patchValue({
        name: this.userData?.fullName,
        designation: this.userData?.designation

      })
      // this.spinner.hide();
      this.GetReviewResponseforLoggedInEmployee()
    },
    )
    // this.spinner.hide();
  }
  //Employee Form Control
  get prf() {
    return this.PerformanceReviewForm.controls;
  }

  getDropDownRatingReviewStatus() {
    // this.spinner.show();
    this.MasterDropdownServiceService.getMasterDropdown('OverallSelfRating').subscribe((dropdownResponce: any) => {
      if (dropdownResponce.isSuccessful == true) { // success
        this.RatingList = dropdownResponce.data;
        // this.spinner.hide();
      }
      else if (dropdownResponce.isSuccessful == false) {
        this.toastr.error(dropdownResponce?.messageDetail?.message, 'Error');
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // ################################## API Call Show ALl Type Of Questions In Performance Review Blank Form ######  
  GetPerfReviewQuetions() {
    this.spinner.show();
    this.allQuestionData = []; // Ensure allQuestionData is initialized as an array
    this.PerformanceReviewService.GetPerfReviewQuetions(this.responseId).subscribe((questionresponce: any) => {
      if (questionresponce.isSuccessful == true) {
        this.yearlyReviewStatus = questionresponce?.data?.perFormanceRevStatus?.value
        const { PerFormanceRevStatus } = questionresponce?.data;
        //     // Format the data into an array
        this.ReviewDataLst = [{
          PerFormanceRevStatus,
        }];
        // this.spinner.hide();
        this.PerformanceRevName = questionresponce?.data?.performanceRevName
        this.description = questionresponce?.data?.notes
        this.PerformanceRevId = questionresponce?.data?.performanceRevId

      }
      else if (questionresponce.isSuccessful == false) {
        this.toastr.error(questionresponce?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.messageDetail?.message);
    });
  }

  GetReviewResponseforLoggedInEmployee() {
    debugger

    this.PerformanceReviewService.GetReviewResponseforLoggedInEmployee(this.responseId).subscribe((questionResp: any) => {
      if (questionResp.isSuccessful == true) { // success
        this.allQuestionData = JSON.parse(JSON.stringify(questionResp?.data?.performanceRevResDetails))
        this.reviewRespId = questionResp?.data?.id
        this.goalsResponce = questionResp?.data?.goalsList

        this.PerformanceRevName = questionResp?.data?.performanceRevName
        const questionsArray = questionResp.data.performanceRevResDetails;

        // Ensure questionsArray is an array before proceeding
        if (Array.isArray(questionsArray)) {
          this.subjectiveQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.category.label === 'Subjective')));
          this.objectiveQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.category.label === 'Objective')));
          this.feedbackQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.category.label === 'Feedback')))
          questionsArray.forEach((question: any) => {
            const validators = question ? [Validators.required] : null;

            this.PerformanceReviewForm.addControl(
              question.id,
              this.fb.control([], validators)
            );

            if (question.ratingEnabled) {
              const defaultValue = question.rating ? question.rating : null;
              this.PerformanceReviewForm.addControl(
                question.id + 'ratting',
                this.fb.control([], validators)
              );

            }
          });
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }

        const data = questionResp?.data; // Ensure Data object exists
        if (data) {
          this.PerformanceReviewForm.patchValue({
            Managers: data.managers,
            Projects: data.projects,
            rating: data.overallSelfRating?.value || null,
          });

          if (data.goalsList) {

            data.goalsList.forEach(element => {
              if (element.goalSequence == 1) {
                this.PerformanceReviewForm.patchValue({
                  goal1: element?.goal,
                  expectedOutcomes1: element?.expOutcome,
                  achievedDate1: element?.achievebyDate ? new Date(element.achievebyDate).toISOString().split('T')[0] : null,
                });
              }
              else if (element.goalSequence == 2) {
                this.PerformanceReviewForm.patchValue({
                  goal2: element?.goal,
                  expectedOutcomes2: element?.expOutcome,
                  achievedDate2: element?.achievebyDate ? new Date(element.achievebyDate).toISOString().split('T')[0] : null,
                });
              }
              else if (element.goalSequence == 3) {
                this.PerformanceReviewForm.patchValue({
                  goal3: element.goal,
                  expectedOutcomes3: element.expOutcome,
                  achievedDate3: element.achievebyDate ? new Date(element.achievebyDate).toISOString().split('T')[0] : null,
                });
              }
            });
          }
          // Sort the PerformanceRevResDetails array based on the "sequence" property
          questionResp.data.performanceRevResDetails.sort((a, b) => a.sequence - b.sequence);

          questionResp.data.performanceRevResDetails.forEach(element => {
            if (element.category.label !== "Feedback") {
              this.patchDynamicControlValue(element.id, element.answer);
            }
            if (element.category.label == "Feedback") {
              this.patchDynamicControlValue(element.id, element.feedbackResponse);
            }
          });

          questionResp?.data?.performanceRevResDetails?.forEach(element => {
            if (element.ratingEnabled == true) {
              this.patchDynamicControlValue1(element.id + 'ratting', element.rating?.value);
            }
          });
          // Check if all fields are filled to disable the form
          this.checkIfFormIsFilled();
          // Disable all fields if the form is valid

          if ((this.yearlyReviewStatus == 674180001 || questionResp?.data?.status?.value == 674180002 || questionResp?.data?.status?.value == 674180003 || questionResp?.data?.status?.value == 674180004 || questionResp?.data?.status?.value == 674180005)) {
            this.PerformanceReviewForm.disable();
          }
        }
      }
      else if (questionResp.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(questionResp?.messageDetail?.message, 'Error');
      }
      else if (questionResp?.messageDetails?.message_code == 204) {
        this.spinner.hide();
        this.GetPerfReviewQuetions()
        // this.toastr.error(questionResp.Message, 'Error');
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetails?.message);
    });

  }
  patchDynamicControlValue1(controlName: string, value: any) {
    if (this.PerformanceReviewForm.contains(controlName)) {
      if (value !== "null") {
        this.PerformanceReviewForm.get(controlName)?.patchValue(value);
      }
    }
  }
  checkIfFormIsFilled() {
    // Get all form control values
    const formValues = this.PerformanceReviewForm.value;
    // Check if all values are not null or empty string
    const isFormFilled = Object.values(formValues).every(value => value !== null && value !== '');
    // Update form disabled state accordingly
    this.isFormDisabled = isFormFilled;
    this.spinner.hide();
  }

  //Worklog Form Control
  get wlf() {
    this.spinner.hide();
    return this.PerformanceReviewForm.controls;
  }

  submit() {debugger
    this.spinner.show();
    this.workFormSubmitted = true;
    if (this.PerformanceReviewForm.valid) {
      let performanceRevResDetails1 = [];
      let goalsList1 = [];
      let keyArray = Object.keys(this.PerformanceReviewForm.value);



      // Iterate through goalsApiResponse and update goalsList in object
      for (let i = 0; i < 3; i++) {
        let goalKey = 'goal' + (i + 1);
        let outcomeKey = 'expectedOutcomes' + (i + 1);
        let achievedDateKey = 'achievedDate' + (i + 1);
        let goal = this.PerformanceReviewForm.value[goalKey];
        let outcome = this.PerformanceReviewForm.value[outcomeKey];
        let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

        // Check if goalSequence is returned from API
        if (this.goalsResponce && this.goalsResponce[i]?.goalSequence) {
          this.sequenceNumber = this.goalsResponce[i].goalSequence;
        }

        // Only add to goalsList if goal is not empty
        if (goal?.trim() !== '') {

          goalsList1.push({
            // id: this.goalsResponce[i].id, // Keep the existing id if available
            id: this.goalsResponce && this.goalsResponce[i]?.id ? this.goalsResponce[i].id : null,
            goal: goal,
            goalSequence: this.sequenceNumber ? this.sequenceNumber : i, // Set the sequence number here
            expOutcome: outcome,
            achievebyDate: achievedDate ? achievedDate : null,
            employeeId: this.userData.ID,
            ...(this.goalsResponce && this.goalsResponce[i]?.id && { id: this.goalsResponce[i].id }) // Include `id` dynamically if it exists
          });

        }
      }

      keyArray.forEach((key: string) => {
        let exist = this.allQuestionData.find((data: any) => data.id === key);
        if (exist) {
          let controlValue = this.PerformanceReviewForm.get(key)?.value;
          // Ensure controlValue is a string
          if (controlValue == null) {
            controlValue = ''; // Set it to an empty string
          } else {
            // Ensure controlValue is a string
            if (typeof controlValue !== 'string') {
              // If controlValue is not a string, convert it to a string
              controlValue = String(controlValue);
            }
          }
          let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

          let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
          let finalRatingValue = null
          if (ratingValue !== null && ratingValue !== undefined) {
            const parsedValue = parseInt(ratingValue.toString().trim(), 10);
            finalRatingValue = isNaN(parsedValue) ? null : parsedValue; // Set to null if parsing fails
          }
          if (exist.category.label !== "Feedback") {
            let detail = {
              id: exist.id,
              question: exist.question,
              answer: controlValue,
              ratingEnabled: exist.ratingEnabled,
              sequence: exist.sequence,
              category: {
                value: exist.category.value.toString() // Convert to string using toString() method
              },

              rating: finalRatingValue !== null ? { value: finalRatingValue } : null, // Set rating dynamically,,
              linkedReviewFY: {
                id: exist.linkedReviewFY.id
              }
            };

            performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
          }
          // No need to construct the final object inside the loop
          if (exist.category.label == "Feedback") {
            let detail = {
              id: exist.id,
              question: exist.question,
              feedbackResponse: controlValue,
              ratingEnabled: exist.ratingEnabled,
              sequence: exist.sequence,
              category: {
                value: exist.category.value.toString() // Convert to string using toString() method
              },

              rating: finalRatingValue !== null ? { value: finalRatingValue } : null, // Set rating dynamically,,
              linkedReviewFY: {
                id: exist.linkedReviewFY.id
              }
            };
            performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
          }
        }
      });
      let object: any = {  // Initialize object outside the loop
        id: this.reviewRespId,
        employeeId: this.userData.ID,
        managers: this.PerformanceReviewForm.value.Managers,
        reportingManId: this.userData?.reportingManager?.id,
        projects: this.PerformanceReviewForm.value.Projects,
        linkedReviewFY: this.PerformanceRevId,
        overallSelfRating:this.PerformanceReviewForm.value.rating ? { value: this.PerformanceReviewForm.value.rating } : null,
        status: {
          value: "674180002"
        },
        performanceRevResDetails: performanceRevResDetails1, // Initialize PerformanceRevResDetails here
        goalsList: goalsList1 // Initialize goalsList here // Assuming there's no logic for goalsList here
      };
      // Send object to API after loop
      this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe((response: any) => {
        if (response.isSuccessful == true) {
          this.Saveresponse = response.data
          this.toastr.success('Your Performance Review record is Submitted successfully and will now proceed for Manager and HR Review.', 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000 // Set to 15000 milliseconds for 15 seconds
          });
          this.router.navigate(['/ESSPortal/performance-reviews-list']);
          this.spinner.hide();
        }
        else if (response.isSuccessful == false) {

          this.toastr.error(response?.messageDetail?.message, 'Error');
          this.spinner.hide();
        }

      }, error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message);
      });
    }
  }

  save() {
    this.spinner.show();

    this.workFormSubmitted = false;
    let performanceRevResDetails1 = [];
    let goalsList1 = [];
    let keyArray = Object.keys(this.PerformanceReviewForm.value);
    // Iterate through goalsApiResponse and update goalsList in object
    for (let i = 0; i < 3; i++) {
      let goalKey = 'goal' + (i + 1);
      let outcomeKey = 'expectedOutcomes' + (i + 1);
      let achievedDateKey = 'achievedDate' + (i + 1);
      let goal = this.PerformanceReviewForm.value[goalKey];
      let outcome = this.PerformanceReviewForm.value[outcomeKey];
      let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

      // Check if goalSequence is returned from API
      if (this.goalsResponce && this.goalsResponce[i]?.goalSequence) {
        this.sequenceNumber = this.goalsResponce[i].goalSequence;
      }

      // Only add to goalsList if goal is not empty
      if (goal?.trim() !== '') {

        goalsList1.push({
          // ID: this.goalsResponce[i].ID, // Keep the existing ID if available
          goal: goal,
          goalSequence: this.sequenceNumber ? this.sequenceNumber : i, // Set the sequence number here
          expOutcome: outcome,
          achievebyDate: achievedDate ? achievedDate : null,
          employeeId: this.userData.ID,
          ...(this.goalsResponce && this.goalsResponce[i]?.id && { id: this.goalsResponce[i].id }) // Include `id` dynamically if it exists
        });

      }
    }




    keyArray.forEach((key: string) => {

      let exist = this.allQuestionData.find((data: any) => data.id === key);
      if (exist) {
        let controlValue = this.PerformanceReviewForm.get(key)?.value;
        // Ensure controlValue is a string
        if (controlValue == null) {
          controlValue = ''; // Set it to an empty string
        } else {
          // Ensure controlValue is a string
          if (typeof controlValue !== 'string') {
            // If controlValue is not a string, convert it to a string
            controlValue = String(controlValue);
          }
        }
        let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

        let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
        let finalRatingValue = null
        if (ratingValue !== null && ratingValue !== undefined) {
          const parsedValue = parseInt(ratingValue.toString().trim(), 10);
          finalRatingValue = isNaN(parsedValue) ? null : parsedValue; // Set to null if parsing fails
        }
        if (exist.category.label !== "Feedback") {
          let detail = {
            id: exist.id,
            question: exist.question,
            answer: controlValue,
            ratingEnabled: exist.ratingEnabled,
            sequence: exist.sequence,
            category: {
              value: exist.category.value.toString() // Convert to string using toString() method
            },

            rating: finalRatingValue !== null ? { value: finalRatingValue } : null, // Set rating dynamically,,
            linkedReviewFY: {
              id: exist.linkedReviewFY.id
            }
          };

          performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
        }
        // No need to construct the final object inside the loop

        if (exist.category.label == "Feedback") {
          let detail = {
            id: exist.id,
            question: exist.question,
            feedbackResponse: controlValue,
            ratingEnabled: exist.ratingEnabled,
            sequence: exist.sequence,
            category: {
              value: exist.category.value.toString() // Convert to string using toString() method
            },

            rating: finalRatingValue !== null ? { value: finalRatingValue } : null, // Set rating dynamically,,
            linkedReviewFY: {
              id: exist.linkedReviewFY.id
            }
          };
          performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
        }
      }
      // else {
      //   this.spinner.hide()
      // }
    });


    let object: any = {
      id: this.reviewRespId,
      employeeId: this.userData.ID,
      managers: this.PerformanceReviewForm.value.Managers,
      reportingManId: this.userData?.reportingManager?.id,
      projects: this.PerformanceReviewForm.value.Projects,
      linkedReviewFY: this.PerformanceRevId,
      overallSelfRating:this.PerformanceReviewForm.value.rating ? { value: this.PerformanceReviewForm.value.rating } : null,
      status: {
        value: "1"
      },
      performanceRevResDetails: performanceRevResDetails1,
      goalsList: goalsList1 // Initialize goalsList here
    };
    // Send object to API after loop
    this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe((response: any) => {
      if (response.isSuccessful == true) {
        this.toastr.success('Your Performance Review record is saved as DRAFT and no action will be taken till you complete it and SUBMIT the form. Please complete it ASAP. ', 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000 // Set to 15000 milliseconds for 15 seconds
        });
        this.Saveresponse = response.data
        this.router.navigate(['/ESSPortal/performance-reviews-list']);
        this.spinner.hide();

      }
      else if (response.isSuccessful == false) {
        if (response?.messageDetail?.message_code != 204) {

          this.toastr.error(response?.messageDetail?.message, 'Error');
          this.spinner.hide();
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  // Function to validate all form fields
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.spinner.hide();
  }

  modelValidate() {
    this.workFormSubmitted = true
    if (this.PerformanceReviewForm.invalid) {

      // Form is invalid, display error messages
      this.validateAllFormFields(this.PerformanceReviewForm);
      this.spinner.hide();
      this.toastr.error('All fields are mandatory', 'Error');
    }
  }
}