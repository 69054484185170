import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaymentInvoiceService } from '../../_services/payment-invoice.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-salary-slip',
  templateUrl: './salary-slip.component.html',
  styleUrls: ['./salary-slip.component.scss']
})
export class SalarySlipComponent implements OnInit {

  @ViewChild('payslipContent', { static: false }) payslipContent!: ElementRef;
  salarySlipId: string;
  salarySlipData: any;
  pdfData: any;
  currencyAUD: any;
  constructor(private paymentServices: PaymentInvoiceService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
    this.spinner.show();
    this.salarySlipId = this.route.snapshot.paramMap.get('id');

    this.employeeMonthlySalary();
    this.getPDFReport();
  }


  employeeMonthlySalary() {
    this.paymentServices.employeeMonthlySalary(this.salarySlipId).subscribe((resp: any) => {
      if (resp.ResponseCode == 100) {
        console.log('salary data', resp.Data)
        this.salarySlipData = resp?.Data[0];
        this.currencyAUD = resp?.Data[0]?.CurrencyCode
        this.spinner.hide();
      }
      else if (resp.ResponseCode == 999) {
        this.toastr.error(resp.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);

    })
  }


  getPDFReport() {
    this.paymentServices.downloadSelerlySlip(this.salarySlipId).subscribe((resp: any) => {

      if (resp.ResponseCode == 100) {
        this.pdfData = resp.Data

      }
      else if (resp.ResponseCode == 999) {
        this.toastr.error(resp.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);

    })

  }

  downloadPDF() {
    if (this.pdfData) {
      const blobData = this.base64toBlob(this.pdfData, 'application/pdf');
      const blobUrl = URL.createObjectURL(blobData);
      const anchor = document.createElement('a');
      anchor.download = 'salary_slip.pdf';
      anchor.href = blobUrl;
      anchor.click();
    }
  }

  private base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }


}
