import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';

@Component({
  selector: 'app-update-employee-goals',
  templateUrl: './update-employee-goals.component.html',
  styleUrls: ['./update-employee-goals.component.scss']
})
export class UpdateEmployeeGoalsComponent implements OnInit {
  empGoalsForm: FormGroup;
  updateGoalsdata: any;
  updateGoalsId: string;
  EmployeeGoalslist: any;
  empId: string;
  EmployeeGoalsUpdateId: any;
  fieldRequiredFlag: boolean;
  statusresionval: any;
  status: any;
  goalFormSubmitted: boolean = false;
  buttonFlag: boolean = false;
  filterStatus: []
  isShow: boolean = false
  statusresionUpdate: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private PerformanceService: PerformanceReviewService,
    private MasterDropdownServiceService : MasterDropdownServiceService,
    public storage: StorageMap,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { this.empId = localStorage.getItem("empId") }

  ngOnInit(): void {
    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateGoalsId = this.route.snapshot.paramMap.get('id');
    }

    this.empGoalsForm = this.fb.group({
      EmployeeName: [''],
      PerformanceYear: [''],
      Goals: [''],
      achievedDate: [''],
      ExpOutcome: [''],
      percentageAchieved: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      statusReason: ['', Validators.required],
      // employeeComments: ['', Validators.required],
      newComments: [''],
      managerComments: [''],
      previousComments: ['']

    });
    this.empGoalsForm.patchValue({
      statusReason: 674180002

    });

    // this.getgolsforUpdation()

    this.dropdownOfStatus()
  }


  //empGoalsForm Form Control
  get egl() {
    return this.empGoalsForm.controls;
  }

  checkValue(event) {
    debugger
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    if (event.target.value > 100) {
      event.target.value = 100;
    }

  }
  dropdownOfStatus() {
    debugger
    this.MasterDropdownServiceService.getMasterDropdown('GoalsStatus').subscribe((responce: any) => {
      if (responce.isSuccessful == true) {
        this.status = responce.data

        this.status.forEach(element => {

          if (element.value == 674180002) {
            this.filterStatus = responce.data.filter(status => status.value === 674180002 || status.value === 1);
          }
          else {
            this.filterStatus = this.status
          }
        });
        this.GetGoalListsById()

      }
      else {
        this.GetGoalListsById()
      }
    })
  }
  OnChangestatusReason(status: any) {
    debugger
    this.statusresionval = status
  }

  //##########################List Of All Employee Goals###########################################
  GetGoalListsById() {
    this.PerformanceService.GetGoalListsById().subscribe((Responcelist: any) => {
      if (Responcelist.isSuccessful == true) {

        this.EmployeeGoalslist = Responcelist.data
        this.EmployeeGoalsUpdateId = Responcelist.data.id
        this.getgolsforUpdation(this.updateGoalsId);
        // this.spinner.hide();

      }
      else if (Responcelist.isSuccessful == false) {
        this.toastr.error(Responcelist?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }

  //****************Patch Values By List Id On Update/ View Form   */
  getgolsforUpdation(positionDetails) {
    debugger;
    this.EmployeeGoalslist.forEach(element => {
      const date = new Date(element.achievebyDate); // Assuming element.achievebyDate is a valid date string
  
      // Adjust date based on system's time zone
      const timeZoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
      const localDate = new Date(date.getTime() - timeZoneOffset);
      const formattedDate = localDate.toISOString().split('T')[0]; // Formats to YYYY-MM-DD
  
      if (element.id == positionDetails) {
        // Patch form values
        this.empGoalsForm.patchValue({
          EmployeeId: this.empId,
          ExpOutcome: element.expOutcome,
          achievedDate: formattedDate,
          previousComments: element.comment,
          managerComments: element.managerComment,
          Goals: element.goal,
          percentageAchieved: element.percentAchieved,
          statusReason: element.statusReason.value,
        });
  
        this.statusresionUpdate = element.statusReason.value;
  
        if (element.statusReason.value == 674180002) {
          this.filterStatus = this.status?.filter(filterElement => {
            return filterElement.label === 'In Progress' || filterElement.label === 'New' ||
              filterElement.value === 1 || filterElement.value === 674180002;
          });
        } else {
          this.filterStatus = this.status;
        }
  
        // Disable status field based on certain statuses
        if ([1, 674180000, 674180001, 674180004, 2].includes(element.statusReason.value)) {
          this.empGoalsForm.get('statusReason').disable();
        }
  
        // Fully lock form and hide Save button if status is 'Achieved'
        if (element.statusReason.value == 674180000) {
          this.empGoalsForm.disable();
          this.buttonFlag = true;
        }
  
        // Lock percentageAchieved and statusReason fields if status is 'Inactive'
        if (element.statusReason.value == 674180004) {
          this.empGoalsForm.get('statusReason').disable();
          this.empGoalsForm.get('percentageAchieved').disable();
        }
  
        // Lock statusReason field if status is 'Overdue'
        if (element.statusReason.value == 674180001) {
          this.empGoalsForm.get('statusReason').disable();
        }
  
        this.spinner.hide();
      }
    });
  }
  

  submit() {
    this.spinner.show();
    this.goalFormSubmitted = true;
    if (this.empGoalsForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.empGoalsForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      this.spinner.hide()
      return;

    }
    let obj = {
      employeeId: this.empId,
      expOutcome: this.empGoalsForm.value.ExpOutcome,
      achievebyDate: this.empGoalsForm.value.achievedDate,
      previousComments: this.empGoalsForm.value.employeeComments,
      managerComment: this.empGoalsForm.value.managerComments,
      goal: this.empGoalsForm.value.Goals,
      // PerformanceYear: this.empGoalsForm.value.PerformanceYear,    
      percentAchieved: this.empGoalsForm.value.percentageAchieved,
      comment: this.empGoalsForm.value.newComments,
      id: this.updateGoalsId,
      statusReason: {

        value: this.empGoalsForm.controls.statusReason.value
      }


    }

    //#############################  Post Updated Details ######################################### 
    this.PerformanceService.postgetgoalsDetailsUpdation(obj).subscribe((responce: any) => {
      if (responce.isSuccessful == true) {
        this.toastr.success(responce?.messageDetail?.message, 'Success', {//Success
          positionClass: 'toast-top-full-width',
        });
        this.router.navigate(['/ESSPortal/employee-goals-list']);
        this.spinner.hide();

      }

      else if (responce.isSuccessful == false) {
        this.toastr.error(responce?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    })
      , error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message)
      }
  }
}

