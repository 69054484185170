import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToDosService {
  apiURL: string = "";
  // allProjects = [];
  authapiURL: string = "";

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL
  }

  // using behiviour subject for passing firlter value of todo 

  // State Inspection
  private selectedIns = new BehaviorSubject<any>(null);
  latestStateInspection = this.selectedIns.asObservable();

  //State Inspection
  updateStateInspection(roles: any) {
    this.selectedIns.next(roles);
  }
  // setAllProjects(){
  //   this.getProjectsByCustomerId(1).subscribe((response: any) => {
  //     this.allProjects = response;
  //   })
  // }

  // getting all to do tasks by posting required data
  getAllToDoTasks(toDoModel: any): Observable<any> {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfEmployeeById/', toDoModel);
  }

  //Only those projects will be shown in which the employee is working.
  getProjectsByCustomerId(contactId: any): Observable<any> { //old API
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetEmployeeProjectsList?isManager=false&employeeId=' + contactId);
  }

  // Only those projects will be shown in which the employee is working.
  GetProjectsList(isManager: any): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetEmployeeProjectsList?IsManager= ' + isManager);
  }

  getProjectsByCustomerId1(contactId: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'ToDos/GetProjectsByCustomerId/' + contactId);
  }

  //posting todosDM and getting customer project(s) info.
  getOpenTodosOfCustomerById(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfCustomerById/', toDoModel);
  }

  // posting todosDM and getting employee project(s) info.
  getOpenTodosOfEmployeeById(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfEmployeeById/', toDoModel);
  }

  // posting todosDM and getting all project(s) info.
  getAllTodosOfEmployeeProjects(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetAllTodosOfEmployeeProjects/', toDoModel);
  }

  getToDosByProjectId(project: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetClientReportedToDosByProjectId/', project);
  }

  getParentToDos(project: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetClientReportedToDosByProjectId/', project);
  }

  // updateToDo(toDosModel: any) { //old API
  //   return this.HttpClient.post(this.apiURL + 'ToDos/UpdateToDo', toDosModel);
  // }

  updateToDo(toDosModel: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/EditToDo', toDosModel);
  }


  getWorkLogsByEmpIdAndToDoId(toDoId: string) {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetWorkLogsByEmpIdOnTodo?ToDoId=' + toDoId);
  }

  //getToDoTasksByToDoIdAndIsManager(toDoId: string, isManager: boolean, flag: number) {
  getToDoTasksByToDoIdAndIsManager(toDoId: string, isManager: boolean) {
    var emp = { id: toDoId, isManager: isManager };
    return this.HttpClient.post(this.authapiURL + "ToDos/SearchTodosTasks?Search=" + toDoId, emp);

  }

  getWorkLogById(id: string) {
    return this.HttpClient.get(this.authapiURL + "ToDos/GetWorkLogById?WorkLogId=" + id);
  }

  checkExistingWorkLog(toDoId: string, date: string, empId: string) {
    return this.HttpClient.get(this.apiURL + "ToDos/CheckExistingWorkLog/" + toDoId + "?date=" + date + "&employee=" + empId);
  }

  saveWorkLog(workLogModel: any) {
    return this.HttpClient.post(this.authapiURL + "ToDos/SaveWorkLog/", workLogModel);
  }
  getAllEmployeeData(id: any) { //OLD API
    return this.HttpClient.get(this.apiURL + 'ToDos/GetAllEmployeeofProject?projectId=' + id);
    // GetAllEmployeeDetailsLists
  }

  GetEmployeeByProjectId(id: any) { //get project employee by project id
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetEmployeeByProjectId?ProjectId=' + id);
    // GetAllEmployeeDetailsLists
  }


  //Get Sprints based on project 
  getProjectSprints(id: any) { // Old API
    return this.HttpClient.get(this.apiURL + 'ToDos/GetProjectSprints?projectId=' + id);
  }
  GetProjectSprintByProjectId(id: any) { // Old API
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetProjectSprintByProjectId?projectId=' + id);
  }


  //is bug yes or no show todo based on condation 
  GetAllToDoByProjectId(id: any) {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetAllToDoByProjectId?projectId=' + id);
  }

  // posting todosDM and getting all project(s) info.
  GetMyObservationToDos(empId: any) {
    return this.HttpClient.get(this.apiURL + 'ToDos/GetMyObservationToDos?empId=' + empId);
  }

  GetLinkedTaskByIdProjectId(projectId: any) {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetLinkedTaskById?ProjectId=' + projectId)
  }

  getToDosDetailsForUpdation(toDoId: string) {

    return this.HttpClient.get(this.authapiURL + "ToDos/GetToDoDetailsByToDoId?ItemId=" + toDoId);
  }
  //get All userstory in parent field sprint based
  GetAllUserStoriesByProjectId(projectId: any, itemType: any) {
    return this.HttpClient.get(this.authapiURL + "ToDos/GetAllUserStoriesByProjectId?projectId=" + projectId + '&itemType=' + itemType)
  }

  postNewToDo(supportReqModel: any) { //create new todo 
    return this.HttpClient.post(this.authapiURL + 'ToDos/CreateNewTodo', supportReqModel);
  }
  //bugs API
  createNewBug(bugObj: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/CreateNewBug', bugObj)
  }

  EditBug(bugObj: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/EditBug', bugObj)
  }

  //My To-Do's API
  // ToDos/GetToDoListBasedOnFilter?filter=3&itemType=0
  GetToDoListBasedOnFilter(filterId: any, itemType: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/GetToDoListBasedOnFilter?filter=' + filterId + '&itemType=' + itemType, {})
  }

  EditUserStory(UserStoryObj: any) {
    return this.HttpClient.post(this.authapiURL + "ToDos/EditUserStory", UserStoryObj)
  }

  GetAllBugsByUserStoryId(UserStoryId: any) {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetAllBugsByUserStoryId?UserStoryId=' + UserStoryId)
  }
  GetActiveItemsInMyProject() {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetActiveItemsInMyProject')
  }
  GetGeneralTaskListInMyProject() {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetGeneralTaskListInMyProject')

  }

  GetUserStoryDetailsByUserStoryId?(toDoId: string) {

    return this.HttpClient.get(this.authapiURL + "ToDos/GetUserStoryDetailsByUserStoryId?ItemId=" + toDoId);
  }
}
