import { Component, OnInit } from '@angular/core';
import { ToDosService } from '../../_services/to-dos.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { SupportRequestService } from '../../_services/support-request.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmployeeDataModel } from '../../_models/employee-data-model';
import { UploadFileDataModel } from '../../_models/upload-file-data-model';
import { OptionSetDataModel } from '../../_models/option-set-data-model';
import * as moment from 'moment';

@Component({
  selector: 'app-raise-bug-all-project',
  templateUrl: './raise-bug-all-project.component.html',
  styleUrls: ['./raise-bug-all-project.component.scss']
})
export class RaiseBugAllProjectComponent implements OnInit {

  supportReqForm: FormGroup;
  allProjectList: any
  upLoadFileList: any
  empData: EmployeeDataModel;
  empId: string = "";
  // testingEnvironmentList: any;
  userPriorityList: any;
  // solzStatusList: Array<OptionSetDataModel> = [];
  datasplit: any;
  supportReqFormSubmitted: boolean = false;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.supportReqForm = this.fb.group({
      // projectId: [null, [Validators.required]],
      // solzStatus: [''],
      itemDescription: [null, [Validators.required]],
      comment: [''],
      userPriority: [''],
      // testingEnvironment: [null, [Validators.required]],
      isBug: ["false", [Validators.required]],
      title: [null, [Validators.required]],
      customerStatus: [''],
      typeOfItem: ["674180002",],
      createdBy: [''],
      isObservation: ["true",[Validators.required]],
      typeOfItems: ['674180002'],
    })
    // this.getSolzStatusOptionSet()
    this.getData()
  }

  // getSolzStatusOptionSet() {
  //   this.spinner.show();
  //   this.supportReqService.getDropDownData("status").subscribe((solzStatusResp: any) => {
  //     if (solzStatusResp.ResponseCode == 100) {
  //       this.solzStatusList = solzStatusResp.Data
  //       this.supportReqForm.patchValue({
  //         customerStatus: 100000000,
  //       })
  //       this.spinner.hide();
  //     }
  //     else {
  //       this.spinner.hide();
  //     }
  //   })
  // }


  async getData() {
    
    // inintialising data here so as to call this function only for updating the to do.
    // this.solzStatusList = JSON.parse(localStorage.getItem('status'));
    // this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));
    this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));
    this.supportReqForm.patchValue({
      userPriority: this.userPriorityList[2].Value,
    });
    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {
      this.empData = empNewRequest.userProfile;
      if (this.empData.userId) {
        this.empId = this.empData?.userId
        this.getProjectList();
      }
      // If Soluzione Emp/Manager logged in
    })
    // }, error => {
    //   this.spinner.hide();
    //   this.toastr.error(error.message);
    // });
  }

  

  getProjectList(): void {
    
    let url = "false"
    this.supportReqService.getProjectListRec(url)
      .subscribe({
        next: (res: any) => {
          this.allProjectList = res.Data;
          this.spinner.hide();
        }
      })
  }
  projectSelected(data: any) {

  }
  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }





  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }



  onSubmit() {
    

    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }
    // var st = this.supportReqForm.value.solzStatus;
    // this.supportReqForm.value.solzStatus = new Object();
    // this.supportReqForm.value.solzStatus = null;

    //for customer Status field data 
    var cst = this.supportReqForm.value.customerStatus;
    this.supportReqForm.value.customerStatus = new Object();
    this.supportReqForm.value.customerStatus.Value = cst;

    var up = this.supportReqForm.value.userPriority;
    this.supportReqForm.value.userPriority = new Object();
    this.supportReqForm.value.userPriority.Value = up;

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.isBug;
    this.supportReqForm.value.isBug = JSON.parse(ib);

    var ti = this.supportReqForm.value.typeOfItem;
    this.supportReqForm.value.typeOfItem = new Object();
    this.supportReqForm.value.typeOfItem.Value = ti;

    this.supportReqForm.value.createdBy = this.empId;

    this.supportReqForm.value.isObservation =  true;

       //TypeofItems Field Hide In html but send value on toto
       var toi = this.supportReqForm.value.typeOfItems;
       this.supportReqForm.value.typeOfItems = new Object();
       this.supportReqForm.value.typeOfItems.value = toi;
 
    // if (this.supportReqForm.value.testingEnvironment != null) {
    //   var te = this.supportReqForm.value.testingEnvironment;
    //   this.supportReqForm.value.testingEnvironment = new Object();
    //   this.supportReqForm.value.testingEnvironment.Value = te;
    // }

    // else {
    //   this.supportReqForm.value.testingEnvironment = new Object();
    //   this.supportReqForm.value.testingEnvironment.Value = 674180004
    // }
    this.supportReqService.postNewToDo(this.supportReqForm.value).subscribe((newRes: any) => {
      if (newRes.ResponseCode == 100) {// success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = newRes.Data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }

        else { // else just show message.
          this.spinner.hide();
          this.toastr.success('Record Submitted scuccesfully', 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          // this.router.navigate(['/ESSPortal/customer/update_to_doos/' + newRes.Data]);
          this.router.navigate(['/ESSPortal/my-observation']);
        }
      }

      else if (newRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(newRes.Message);
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }


  async uploadFiles(attachFiles: any) {
    await this.supportReqService.postUploadFilesWithToDos(attachFiles).subscribe((upRes: any) => {
      if (upRes.ResponseCode == 100) { // success
        this.toastr.success('Record Submitted scuccesfully', 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        }
        );
        // this.router.navigate(['/ESSPortal/customer/update_to_doos/' + this.upLoadFileList[0]?.ID]);
        this.router.navigate(['/ESSPortal/my-observation']);
      }

      else if (upRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(upRes.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }



}
